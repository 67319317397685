<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <div slot="list" slot-scope="data">
      <b-card>
        <b-table
          :items="items"
          :fields="table.fields"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          no-local-sorting
          responsive
        >
          <template slot="name" slot-scope="data">
            {{ data.item.person && data.item.person.fields && data.item.person.fields.name }}
          </template>

          <template slot="responsible" slot-scope="data">
            <responsible :responsible="data.value"></responsible>
          </template>

          <template slot="budget" slot-scope="data"> {{ data.value | formatNumber }} &#8381; </template>

          <template slot="company" slot-scope="data">
            <a
              v-if="data.item.extra.amocrm_company_id"
              target="_blank"
              :href="'https://rocketcrm.amocrm.ru/companies/detail/' + data.item.extra.amocrm_company_id"
              >Перейти</a
            >
            <template v-else>-</template>
          </template>
        </b-table>
      </b-card>
    </div>
  </list>
</template>

<script>
  import entityMixins from '../../mixins/entity'

  export default {
    name: 'ClientsList',
    components: {
      list: () => import('../../components/List'),
      responsible: () => import('../../components/Responsible'),
    },
    mixins: [entityMixins],
    data() {
      return {
        table: {
          fields: [
            {
              key: 'id',
              label: 'ID',
            },
            {
              key: 'name',
              label: 'Название',
              sortable: true,
            },
            {
              key: 'responsible',
              label: 'Ответственный',
              class: 'text-center',
            },
            {
              key: 'count_projects',
              label: 'Кол-во сделок',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'budget',
              label: 'Суммарный бюджет',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'company',
              label: 'Компания',
              class: 'text-center',
            },
          ],
        },
        filters: [
          {
            name: 'name',
            label: 'Быстрый поиск',
          },
        ],
      }
    },
  }
</script>
